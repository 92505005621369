.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #CD9852;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
/* Style for schedule cells */
.schedule th {
  background-color: #ffffff;
  
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
   /* Light gray background */
}




.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Add borders and padding to schedule cells */
.schedule-cell {
  position: relative;
   /* Remove padding to ensure color fills entire cell */
}

/* Style the table header */
.schedule th {
  background-color: #f0f0f0;
  text-align: center;
  padding: 25px;
}


.schedule {
  font-family: Arial, sans-serif;
}

.course-selection {
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
  background-color: #791919;
}

th {
  background-color: #f2f2f2;
  background-color: #791919;
}

tbody td {
  position: relative;
}

/* Style for individual course cells */
.course-cell {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Prevent content from overflowing */
}

.course-text {
  font-weight: bold;
  font-size: 13px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  text-align: center; /* Center text within the container */
  color: rgb(0, 0, 0);
  padding: 5px;
  box-sizing: border-box;
  word-wrap: break-word;
  overflow: hidden;
}

.course-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px; /* Adjust the gap between AMBIENTE and GRUPO */
}



/* Style for courses displayed in red */
.red {
  color: rgb(255, 123, 0);
}


body {
  background-color: #CD9852; /* Replace with your desired background color */
  
  background-size: contain; /* Adjust as needed */
  background-repeat: no-repeat;
  background-position: center center;
  /* Additional styles */
}

/* Add watermark to table */
.schedule {
  position: relative; /* Ensure relative positioning for absolute watermark */
}

.schedule:after {
  content: '';
  position: absolute;
  top: 55%; /* Adjust as needed */
  left: 50%; /* Adjust as needed */
  transform: translate(-50%, -50%); /* Center the watermark */
  opacity: 0.40; /* Adjust opacity as desired */
  pointer-events: none; /* Ensure clicks go through watermark */
  z-index: 1; /* Send the watermark behind table content */
  background-image: url('./logo7.png'); /* Replace with your watermark image path */
  background-size: contain; /* Adjust sizing as needed */
  background-repeat: no-repeat; /* Prevent repetition */
  width: 500px; /* Adjust width */
  height: 500px; /* Adjust height */
}

/* Ensure the table cells are still visible */
th, td {
  position: relative;
  z-index: 1; /* Ensure content appears above the watermark */
}




.schedule th, .schedule td {
  border: 1.5px solid #000 !important; /* Increase border width and use a darker color */
}

.col-md-9 {
  margin-left: 500px; /* Adjust as needed */
  margin-top: -200px; /* Adjust as needed */
}


.schedule-image {
  
  height: 200px;
  width: 200px;
   /* Adjust this value as needed */
}