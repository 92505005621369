.App-header {
    background-color: #CD9852;
    background-image: url('./TC.jpg');
    background-size: contain; /* Adjust as needed */
  background-repeat: no-repeat;
  background-position: center center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    font-family: "Lucida Handwriting";
  }



  /* Add this CSS to differentiate background colors */
.course-color-1 {
    background-color: rgba(255, 0, 0, 0.3); /* Red color with 30% opacity */
  }
  
  .course-color-2 {
    background-color: rgba(0, 255, 0, 0.3); /* Green color with 30% opacity */
  }
  
  .course-color-3 {
    background-color: rgba(44, 199, 30, 0.3); /* Blue color with 30% opacity */
  }
  .course-color-4 {
    background-color: rgba(214, 21, 85, 0.3); /* Blue color with 30% opacity */
  }
  .course-color-5 {
    background-color: rgba(7, 63, 85, 0.3); /* Blue color with 30% opacity */
  }
  .course-color-6 {
    background-color: rgb(251, 255, 0); /* Blue color with 30% opacity */
  }
  .course-color-7 {
    background-color: rgba(226, 106, 106, 0.3); /* Blue color with 30% opacity */
  }
  /* Add more classes for additional colors as needed */
  

  .facebook-link {
    color: #000000;  /* Facebook blue */
  }
  
  .instagram-link {
    color: #000000;  /* Instagram pink */
  }
  
  .tiktok-link {
    color: #000000;  /* TikTok black */
  }
  
  /* If you want to change the color when hovering over the link */
  .facebook-link:hover, .instagram-link:hover, .tiktok-link:hover {
    color: #555555be;  /* Dark grey on hover */
  }

  .image-right {
    
    padding-right: 200px; /* Adjust the padding as needed */
  }
  